import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
// import { withRouter } from 'react-router';
import Language from "../components/common/Language";
import Currency from "../components/common/Currency";
import style from "./commonstyle.module.scss";
import { connect } from "react-redux";
//import logo1 from "../../assets/images/dashboard/logo.svg";
import Logo from "../../assets/images/logos/logo-dark.png";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import AppInfoservice from "../../service/common/Appinfo";
import profilePic from "../../assets/images/nophoto/no_photo.jpg";
import { flexbox } from "@mui/system";
//notification materialUI
// import NewspaperIcon from '@mui/icons-material/Newspaper';
// import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
// import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
// import PaymentIcon from '@mui/icons-material/Payment';

// import NavNotification from './NavNotification';
//custum lang dropsown lang button
const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ cursor: "pointer" }}
    >
        {/* Render custom icon here */}
        {children}
    </span>
));
function mapStateToProps(state) {
    const { Mail } = state;
    const { Notification } = state;
    const { Menu } = state;

    return { Mail: Mail, Notification: Notification, Menu: Menu };
}
const MainNavbar = (props) => {
    let pathto;

    const [state, setState] = useState({
        icononly: false,
    });
    const [logo, setLogo] = useState();

    // state = {
    //   icononly: false
    // };

    function toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }
    function toggleRightSidebar() {
        document.querySelector(".tp_msg_btn").classList.toggle("show");
    }
    function toggleIcononly() {
        document.body.classList.toggle("sidebar-icon-only");
        setState({ icononly: !state.icononly });
    }

    useEffect(() => {
        document.querySelector("#dropdown-basic").classList.remove("btn");
        document
            .querySelector("#dropdown-basic")
            .classList.remove("btn-primary");
        const app = new AppInfoservice();
        app.Appinfo().then((res) => {
            if (res.status) {
                setLogo(res.data.company_info.logo);
            }
        });
    }, []);

    const mainClick = () => {
        const urlData = Menu.Menu.find(
            (element) => element.title === "register"
        );
        if (urlData.url) {
            window.location.href = urlData.url;
        } else {
            props.history.push("/register");
        }
    };
    const mainClick1 = (url) => {
        props.history.push(url);
    };

    const viewmail = (evt) => {
        evt.preventDefault();
        props.history.push("/mailbox#inbox");
    };

    const handler = (evt) => {
        evt.preventDefault();
        // props.history.push('/mailbox#inbox')
    };

    //notification
    const notificationContent = (item) => {
        if (item.type == "material_added") {
            return (
                <span>
                    {t("Common.you_have")} {" new Document"}
                </span>
            );
        } else if (item.type == "news_added") {
            return (
                <span>
                    {t("Common.you_have")} {" new News"}
                </span>
            );
        } else if (item.type == "epin_allotted") {
            return (
                <div
                    style={{ display: "flex", gap: "5px", marginLeft: "15px" }}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            backgroundColor: "#d0d0e5",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            textAlign: "center",
                        }}
                    >
                        {item.count}
                    </span>{" "}
                    {t("Common.epin_request_confirmed")}
                    {" of "}${item.amount}
                </div>
            );
        } else {
            return (
                <span>
                    <span>Payout Released ${item.amount}</span>
                </span>
            );
        }
    };

    const viewNotification = (items, item) => {
        pathto =
            item.type === "material_added"
                ? "/downloadDocument"
                : item.type === "epin_allotted"
                ? "/epin"
                : item.type === "news_added"
                ? "/news"
                : "/payout";
        props.history.push(pathto);
        props.notificationClick(item.id);
    };

    const { Mail, t } = props;
    const { Notification } = props;
    const { Menu } = props;
    let name_title;

    // const mailHandler = (evt)=>{
    // console.log("=======wtwtre")
    //   evt.preventDefault();
    //   //window.location.href = '/mailbox';
    //   props.history.push({
    //     pathname:'/mailbox'
    //   })
    // }
    const total_count =
        Notification.pin_count +
        Notification.document_count +
        Notification.news_count +
        Notification.payout_count;
    const urlData = Menu.Menu.find((element) => element.title === "register");
    const { currentTheme } = useSelector((state) => state.Theme);

    return (
        <div className={style.main_contant_sec}>
            <div className={style.topbar_section}>
                <div className={style.logo_sec}>
                    <div
                        className={`${
                            currentTheme == "theme-dark"
                                ? `${style.dark_mode_menubartogglebtn}`
                                : `${style.menubartogglebtn}`
                        }`}
                        onClick={() => {
                            toggleIcononly();
                        }}
                    >
                        <span></span>
                    </div>
                    <a href={"dashboard"}>
                        <div className={style.logo_cn}>
                            <img src={logo ? logo : Logo} alt=""></img>
                        </div>
                    </a>
                </div>
                <div
                    className={style.top_notification_sec}
                    style={{
                        width: "100px",
                        float: "left",
                        display: "flex",
                        height: "51px",
                    }}
                >
                    <Dropdown style={{}}>
                        <Dropdown.Toggle variant="Secondary">
                            <Currency />
                        </Dropdown.Toggle>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle variant="Secondary">
                            <Language ismainlayout={true} />
                        </Dropdown.Toggle>
                    </Dropdown>
                </div>
                <div className={style.top_notification_user_sec}>
                    <div className={style.top_notification_sec}>
                        <Dropdown
                            className={`${style.top_notification_ico} ${style.tp_msg_btn} ${style.materialiconsoutlined}`}
                        >
                            <Dropdown.Toggle variant="Secondary">
                                <MarkunreadOutlinedIcon />
                                {Mail.count > 0 && (
                                    <span className={style.notification_count}>
                                        {Mail.count}
                                    </span>
                                )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                                className={style.notification_msg_box}
                            >
                                {Mail.count > 0 ? (
                                    <div className={style.mailDropDown}>
                                        <Dropdown.Item
                                            className={
                                                style.notification_msg_box_cnt_row
                                            }
                                            href="!#"
                                            onClick={(evt) =>
                                                evt.preventDefault()
                                            }
                                        >
                                            <p
                                                className={
                                                    currentTheme == "theme-dark"
                                                        ? "mb-0 font-weight-medium float-left text-white"
                                                        : "mb-0 font-weight-medium float-left"
                                                }
                                            >
                                                {t("Common.youHaveNewMail", {
                                                    count: Mail.count,
                                                })}{" "}
                                            </p>
                                            <Link
                                                to="/mailbox"
                                                className={`badge badge-pill badge-primary float-right ${style.viewBadge}`}
                                            >
                                                {t("Common.viewAll")}
                                            </Link>
                                        </Dropdown.Item>
                                        <div className="notification_msg_box_cnt">
                                            {Mail.mail_data.map(
                                                (item, index) =>
                                                    // <>
                                                    [
                                                        // <div className="animate__animated animate__fadeInRight" key={`divider-${index}`}></div>,
                                                        <Dropdown.Item
                                                            className={
                                                                style.notification_msg_box_cnt_row
                                                            }
                                                            href="!#"
                                                            onClick={viewmail}
                                                            key={index}
                                                        >
                                                            {/* <div className="preview-thumbnail">
                                    <i className="mdi mdi-alert m-auto text-primary"></i>
                                  </div> */}
                                                            <div className="preview-item-content">
                                                                <span
                                                                    className={`${style.image} mr-2`}
                                                                >
                                                                    <img
                                                                        src={
                                                                            item.image
                                                                                ? item.image
                                                                                : profilePic
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                {/* <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>Application Error</Trans></h6>
                                    <p className="font-weight-light small-text mb-0"> <Trans>Just now</Trans> </p> */}

                                                                <span className="msg_box_cnt_t">
                                                                    {
                                                                        item.username
                                                                    }
                                                                    <br />
                                                                    {
                                                                        item.mailadsubject
                                                                    }
                                                                    <br />
                                                                    <small>
                                                                        {
                                                                            item.mailadiddate
                                                                        }
                                                                    </small>
                                                                </span>
                                                            </div>
                                                        </Dropdown.Item>,
                                                    ]
                                                // </>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <Dropdown.Item
                                        className={
                                            style.notification_msg_box_cnt_row
                                        }
                                        href="!#"
                                        onClick={(evt) => evt.preventDefault()}
                                    >
                                        {/* <p className="mb-0 font-weight-medium float-left">{t('Common.youHaveNewMail',{count:'no'})} </p> */}
                                        {/* <span className="badge badge-pill badge-primary float-right">View all</span> */}
                                        <strong>
                                            {t(
                                                "Common.youHaveNoNewmailNotification"
                                            )}
                                        </strong>
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* <a href="#"><div class="top_notification_ico tp_msg_btn"><span class="material-icons-outlined ">markunread</span>
                            <div class="notification_msg_box">
                                <div class="notification_msg_box_head">Messages</div>
                                <div class="notification_msg_box_cnt">
                                    <div class="notification_msg_box_cnt_row">
                                        Message Name
                                        <span>12/05/2021</span>
                                    </div>
                                </div>
                            </div>
                        </div></a> */}

                        <Dropdown
                            className={`${style.top_notification_ico} ${style.tp_not_btn} ${style.materialiconsoutlined}`}
                        >
                            <Dropdown.Toggle variant="Secondary">
                                <span>
                                    <NotificationsOutlinedIcon />
                                </span>
                                {Notification.count > 0 && (
                                    <div className={style.notification_count}>
                                        {Notification.count}
                                    </div>
                                )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                                className={style.notification_msg_box}
                            >
                                {Notification.count > 0 ? (
                                    <div className={style.mailDropDown}>
                                        <Dropdown.Item
                                            className={
                                                style.notification_msg_box_cnt_row
                                            }
                                            href="!#"
                                            onClick={(evt) =>
                                                evt.preventDefault()
                                            }
                                        >
                                            <p
                                                className={
                                                    currentTheme == "theme-dark"
                                                        ? "mb-0 font-weight-medium float-left text-white"
                                                        : "mb-0 font-weight-medium float-left"
                                                }
                                            >
                                                {Notification.count + " "}
                                                {t(
                                                    "Common.MissedNotification"
                                                )}{" "}
                                            </p>
                                            {/* <Link
                        to="/mailbox"
                        className={`badge badge-pill badge-primary float-right ${style.viewBadge}`}
                      >
                        {t("Common.viewAll")}
                      </Link> */}
                                        </Dropdown.Item>
                                        <div className="notification_msg_box_cnt">
                                            {Notification.notification_data.map(
                                                (item, index) =>
                                                    // <>
                                                    [
                                                        // <div className="animate__animated animate__fadeInRight" key={`divider-${index}`}></div>,
                                                        <Dropdown.Item
                                                            className={
                                                                style.notification_msg_box_cnt_row
                                                            }
                                                            //href="!#"
                                                            onClick={(
                                                                items
                                                            ) => {
                                                                viewNotification(
                                                                    items,
                                                                    item
                                                                );
                                                            }}
                                                            key={index}
                                                        >
                                                            <div
                                                                className="preview-item-content"
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <span
                                                                    className={`${style.image} mr-2`}
                                                                >
                                                                    <h1>
                                                                        <i
                                                                            class="fa fa-money"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </h1>
                                                                </span>

                                                                {notificationContent(
                                                                    item
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    margin: "2px",
                                                                    textAlign:
                                                                        "end",
                                                                }}
                                                            >
                                                                {item.date}
                                                            </div>
                                                            <hr
                                                                style={{
                                                                    marginTop:
                                                                        "0px",
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                            />
                                                        </Dropdown.Item>,
                                                    ]
                                                // </>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <Dropdown.Item
                                        className={
                                            style.notification_msg_box_cnt_row
                                        }
                                        href="!#"
                                        onClick={(evt) => evt.preventDefault()}
                                    >
                                        {/* <p className="mb-0 font-weight-medium float-left">{t('Common.youHaveNewMail',{count:'no'})} </p> */}
                                        {/* <span className="badge badge-pill badge-primary float-right">View all</span> */}
                                        <strong>
                                            {t(
                                                "Common.youHaveNoNewNotification"
                                            )}
                                        </strong>
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                            {/* <Dropdown.Item className={style.notification_msg_box_head} href="#/action-1" onClick={evt => evt.preventDefault()}>{t('Common.viewAll')}</Dropdown.Item> */}
                        </Dropdown>
                    </div>

                    <div className={style.top_user_acc_sec}>
                        <Dropdown className={` ${style.dropdowntoggle1}`}>
                            <Dropdown.Toggle
                                variant="Secondary"
                                id="dropdown-basic"
                            >
                                <span>
                                    <img
                                        className="img-xs rounded-circle"
                                        src={
                                            props.image
                                                ? props.image
                                                : profilePic
                                        }
                                        alt="Profile"
                                    />
                                </span>{" "}
                                {props.userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={style.dropdownmenu}>
                                <Dropdown.Item
                                    as={Link}
                                    to="/profile"
                                    className={style.dropdownitem}
                                >
                                    {" "}
                                    {/* <Link
                    style={{ textDecorationLine: "none", color: "black" }}
                    to="/profile"
                  > */}
                                    {t("Common.profile")}
                                    {/* </Link> */}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as={Link}
                                    to="/logout"
                                    className={style.dropdownitem}
                                >
                                    {/* <Link
                    style={{ textDecorationLine: "none", color: "black" }}
                    to="/logout"
                  > */}
                                    {t("Sidemenu.logout")}
                                    {/* </Link> */}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* <div className={`btn-group ${style.dropdowntoggle1}`}>
                  <button type="button" className=" " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>U</span>User Name
                  </button>
                  <div className={style.dropdownmenu}>
                    <a className={style.dropdownitem} href="#">Profile</a>
                    <a className={style.dropdownitem} href="#">Another action</a>
                    <a className={style.dropdownitem} href="#">Something else here</a>
                    <div className={style.dropdownitem}></div>
                    <a className={style.dropdownitem} href="#">Logout</a>
                  </div>
                </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(
    connect(mapStateToProps)(withRouter(MainNavbar))
);
