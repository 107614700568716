import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import companyName from "../../../../store/mobxStore/companyName";
import Forms from "../../../components/auth/Forms";
import ForgotPasswordService from "../../../../service/Auth/ForgotPassowrd";
import ForgotButton from "../../../components/auth/ForgotButton";
import { BASE } from "../../../../configuration/apiconfig";
import { Alert, Button, Form } from "react-bootstrap";
import { AlertMessage } from "../../../components/common";
import { useParams, useHistory } from "react-router-dom";
import style from './auth.module.scss';


function ForgotPassword({ t }) {
  const history = useHistory();
  var captchvalue;
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = useState(false);

  let styles = {
    marginTop: "-1px",
    marginLeft: "23px",
    color: "#a94442",
    fontSize: "12px",
  };
  const service = new ForgotPasswordService();

  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };

  const [state, setState] = useState({
    userName: "",
    Email: "",
    captcha: "",
    captchvalue: "",
    isLoggedError: false,
    notify: notifyInintialState,
    captchaImage: "",
    FormError: {
      userName: "",
      Email: "",
      captcha: "",
    },
    btnDisabled: false,
    loading: false // add loading state
  });
  const [show, setShow] = useState(false);

  // useEffect(()=>{
  //  let adminName = localStorage.getItem('adminname');
  //   service.getApi(adminName).then((res) => {
  //     if (res.status) {
  //       localStorage.setItem('apiKey', res.data.key);
  //     }
  //     else {
  //       alert("Invalid Admin user name");
  //     }
  //   })
  // },[])

  const formValid = (formError) => {
    console.log(formError)
    let valid = true;
    Object.values(formError).forEach(
      (val) => console.log(val)
    );
    console.log(valid)
    return valid;
  };

  onchange = (e) => {
    // const service = new ForgotPasswordService();
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const blurhandler = (name) => {
    if (name === "userName") {
      const formData = {
        user_name: state.userName,
      };
      service.checkUsername(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1043") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                // userName: "Username_not_exists",
              },
            }));
          }
          else if (res.error.code == "1070") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                // userName: "not_valid_username",
              },
            }));
          }
        } else {
          //   console.log("gafdgsdgfsdg")
          //  // reloadCaptcha(state.userName);
          //   setState((prevState) => ({
          //     ...prevState,
          //     FormError: {
          //       ...prevState.FormError,
          //       userName: "",
          //     },
          //   }));
        }
      });
    }
    if (name === "Email") {
      const formData = {
        e_mail: state.Email,
        user_name: state.userName,
      };
      service.checkUsermail(formData).then((res) => {
        console.log("🚀 11 res:", res)
        if (!res.status) {
          if (res.error.code == "1048") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                // Email: "email_not_exists",
              },
            }));
          }
        } else {
          // setState((prevState) => ({
          //   ...prevState,
          //   FormError: {
          //     ...prevState.FormError,
          //     Email: "",
          //   },
          // }));
        }
      });
    }
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormError;
    switch (name) {
      case "userName":
        FormError.userName = value.length === 0 ? "unamereq" : "";
        break;
      case "Email":
        FormError.Email = value.length === 0 ? "emailreq" : "";
        break;
      case "captcha":
        FormError.captcha = value.length === 0 ? "captchareq" : "";
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    setIsLoading(true); // set isLoading to true when API call is made
    console.log(state.FormError.userName);
    e.preventDefault();
    requiredValid(state.userName, "userName");
    requiredValid(state.Email, "Email");
    if (state.FormError.userName.length === 0 && state.FormError.Email.length === 0) {
      const formData = {
        username: state.userName,
        email: state.Email,
      };
      service.forgot_password(formData)
        .then((res) => {
          // set isLoading to false when API call is completed
          console.log("🚀10121", res.status);
          if (res.data && res.data.message) {
            setIsLoading(false)
            showNotify(
              "success",
              t("Common.success"),
              t(res.data.message)
            );
            setTimeout(() => {
          setIsLoading(true); // set isLoading to false when API call is completed

              history.push("/login");
            }, 3000);
          } else if (res.error && res.error.description) {
            setIsLoading(false)
            showNotify(
              "danger",
              t("Common.error"),
              t(res.error.description)
            );
          } else {
            showNotify(
              "danger",
              t("Common.error"),
              t("validation.invalidCaptcha")
            );
          }
        });
    }
  };

console.log("loadinggg",isLoading)
  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };

  const reloadCaptcha = (userName) => {
    service.getCaptcha(userName).then((res) => {
      console.log(res);
      let outside = URL.createObjectURL(res);
      setState((prev) => ({
        ...prev,
        captchaImage: outside,
      }));
    });
  };
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      notify: notifyInintialState,
    }));
  };

  const CaptchaChage = (value) => {
    setState((prevState) => ({
      ...prevState,
      captcha: value,
      FormError: {
        ...prevState.FormError,
        captcha: "",
      },
    }));
  };

  return (
    <>
      <div className="mb-2">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Common.forgotPassword")}
          </title>
        </Helmet>

        {state.notify.show && (
          <AlertMessage
            type={state.notify.type}
            message={state.notify.message}
            show={state.notify.show}
            dismiss={notifyDissmissed}
          />
        )}
        <form id="forgot-form">
          <Forms
            type="text"
            name="userName"
            change={onchange}
            error={state.FormError.userName}
            blurhandler={blurhandler}
            from={"forgot"}
          />
          <Forms
            type="email"
            name="Email"
            change={onchange}
            error={state.FormError.Email}
            blurhandler={blurhandler}
            from={"forgot"}
          />
          {state.loading && <div className="spinner"></div>}

          <button
            className={`${style.loginFormBtn} ${isLoading ? style.loading : ''}`}
            onClick={onSubmit}
            // disabled={state.btnDisabled || isLoading}
            // disabled={true}
            disabled={isLoading}
          >
            {isLoading ?  <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              /> : ''}
            {t('Login.PlaceHolder.sendreq')}
          </button>


          {/* <Button 
          onClick={onSubmit} 
          
          disabled ={state.btnDisabled}
          >{t('Login.PlaceHolder.sendreq')}</Button> */}
        </form>
      </div>
    </>
  );
}
export default withTranslation()(ForgotPassword);
