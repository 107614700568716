import React, { useState } from "react";
import style from "./profile.module.scss";
import { FormHead, FormsControl, Buttons } from "./";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button } from "react-bootstrap";

function PaymentDetails(props) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(props.data);
    const history = useHistory();
    const changeHandler = (e, isReq) => {
        const { name, value } = e.target;
        setData(
            [...data].map((object) => {
                if (object.code === name) {
                    var regexPattern = new RegExp(
                        /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/
                    );
                    if (name == "paypalAccount" && !regexPattern.test(value)) {
                        return {
                            ...object,
                            value: value,
                            error: "validation.invalidEmail",
                            errorField: {
                                field: name,
                            },
                        };
                    }
                    return {
                        ...object,
                        value: value,
                        error: value.length === 0 && isReq ? name : "",
                    };
                } else return object;
            })
        );
    };
    const cancelButtonClick = () => {
        setData(props.data);
        props.cancelbtn();
    };

    const linkSubmit = () => {
        setLoading(true);

        const service = new ProfileService();

        service.getstripeId().then((res) => {
            if (res.status) {
                setLoading(false);
                // props.showNotify('danger','danger','profile.'+res.data.message)
                // props.cancelbtn();
                // props.updateSuccess(data,props.stateKey);

                if (res.data.url) {
                    window.location.href = res.data.url;
                }
            } else {
                setLoading(false);
                props.showNotify("danger", "danger", res.data.message);
            }
        });
    };

    const onSubmit = () => {
        let error = false;
        let formdata = {};
        data.forEach((element) => {
            if (element.error) {
                error = true;
            } else {
                formdata = {
                    ...formdata,
                    [element.code]: element.value,
                };
            }
        });
        if (!error) {
            const service = new ProfileService();
            service.UpdatePaymentDetails(formdata).then((res) => {
                if (res.status) {
                    props.showNotify(
                        "success",
                        "success",
                        "profile." + res.data.message
                    );
                    props.cancelbtn();
                    props.updateSuccess(data, props.stateKey);
                    // props.getData();
                } else {
                    if (res.error.code === 1002) {
                        history.push("/logou");
                    } else {
                        props.showNotify(
                            "danger",
                            "error",
                            "validation.checkValueYouHaveSubmitted"
                        );
                    }
                }

                // props.cancelbtn();
            });
        }
    };
    const changeDropDown = (key, id) => {
        setData(
            [...data].map((object) => {
                if (object.code === id.code) {
                    return {
                        ...object,
                        value: key,
                    };
                } else return object;
            })
        );
    };
    return (
        <div
            className={`${
                currentTheme == "theme-dark"
                    ? `${style.dark_mode_PaymentDetails}`
                    : `${style.PaymentDetails}`
            }`}
        >
            <FormHead
                title="paymentDetails"
                change={props.change}
                status={props.status}
                show={props.isEditable}
            />
            {data.map((forms, index) => {
                return (
                    <FormsControl
                        key={index}
                        change={changeHandler}
                        disabled={true}
                        status={props.isEditable}
                        data={forms}
                        dropdownChange={changeDropDown}
                    />
                );
            })}
            {/* <Button onClick={linkSubmit}>
                {" "}
                {loading ? (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{
                            marginRight: "5px",
                            backgroundColor: "transparent",
                        }}
                    />
                ) : (
                    ""
                )}
                {"Link to Stripe"}
            </Button> */}
            <br />
            <br />
            {!props.isEditable && (
                <Buttons cancel={cancelButtonClick} submitHandler={onSubmit} />
            )}
        </div>
    );
}

export default PaymentDetails;
