import React from 'react';
import AddForm from '../../components/crm/AddForm';

//css
import {Container} from 'react-bootstrap';
import style from './crm.module.scss';
import {  BackButton } from '../common';
const  CrmAddLead= (props) => {
    return (
        <div className={style.addLeadContainer}>
            <BackButton/>
            <Container fluid className={`p-3`}>
                <AddForm 
                    {...props}
                    BtnName="addLead"
                />
            </Container>
        </div>
    );
}
 
export default CrmAddLead ;